import { Navigate, Outlet } from 'react-router-dom';

import { isOagfAdmin, OrganizationDetails } from 'core/helpers';
import { getStoredUser } from '../pages/auth/user-storage';

type Props = {
    children: any;
    roles: string;
};

export const isUserLoggedIn = () => {
  const authed = getStoredUser();
  return authed && authed.idToken;
};


export const isPathExists = () =>
    OrganizationDetails?.organizationUser?.resource?.some(
        (item: { link: string }) =>
            window.location.pathname === item.link || window.location.pathname.startsWith(item.link),
    );


const PrivateRoutes = () => {
    const authed = getStoredUser();

    return authed && authed.idToken ? (
        authed ? (
            <Outlet />
        ) : (
            <Navigate
                to={{
                    pathname: '/unauthorized',
                }}
            />
        )
    ) : (
        <Navigate
            to={{
                pathname: '/auth/login',
            }}
        />
    );
};
export default PrivateRoutes;

export const PrivateOagfAdmin = () => {
    return isOagfAdmin() ? (
        <Outlet />
    ) : (
        <Navigate
            to={{
                pathname: '/admin',
            }}
        />
    );
};

