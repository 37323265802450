import { NewOAGFLogoLogin, back, envelope, lockSimple, loginImg } from 'core/assets/imgs';
import {  MaskAndSave, isOagfAdmin } from 'core/helpers';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useRequestSignin, useUser } from './hooks/useUser';
import { useAuth } from './useAuth';
import { ErrorToast } from 'app/components/toast';

const Schema = Yup.object().shape({
    username: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
});

const initialValues = {
    username: '',
    password: '',
    rememberMe: '',
};

export const hasRoleOAGFAdmin = (roles) => {
    return roles?.some((role) => role?.id === 'ROLE_OAGF_ADMIN');
};

const Login: React.FC = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        document.title = 'Login to portal';
    }, []);

    const [loading, setLoading] = useState(false);

    const auth = useAuth();
    const { user, updateUser } = useUser();

    const { data, error, mutate, isLoading } = useRequestSignin();

    const handleSubmit = (values) => {
        setLoading(true);

        mutate(values, {
            onSuccess: (res) => {
                handleSuccess(res);
                setLoading(false);
            },
            onError: (error) => {
                console.log(error?.response?.data?.message);

                ErrorToast(error?.response?.data?.message)
                
                 setLoading(false)
            }, // Handle errors gracefully
        });
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: handleSubmit,
    });

    const handleSuccess = (res) => {
        const { idToken, id, organizationUser } = res.data;

        updateUser(res.data);

        // Use a loop to avoid redundant calls to MaskAndSave
        const dataToSave = [
            { key: 'token', value: idToken },
            { key: 'userLogin', value: 'true' },
            { key: 'orgId', value: id },
            { key: 'login', value: organizationUser.login },
        ];

        dataToSave.forEach(({ key, value }) => MaskAndSave(key, value));

        // Navigate based on user role
        const destination = isOagfAdmin() ? '/admin/oagf-overview' : '/admin';
        navigate(destination, { replace: true });
        window.location.reload();
    };

    const done = () => {
        navigate('/mda-home');
    };

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div>
            {/* <NavBar /> */}

            <div className="login-root">
                <div className="login-aside">
                    <div className="login-aside-img">
                        <div className="  m-auto mb-3 responsive-card cursor-pointer " onClick={done}>
                            <img src={back} alt="invoice" className="icon-style" />
                        </div>
                        <div className="img-login">
                            <img src={loginImg} alt="LoginImg" />
                        </div>
                    </div>
                    <div className="login-aside-footer">
                        <div>
                            <span>
                                The Treasury Portal makes all payments, collections and reconciliation processes easy
                                for you. Sign in to experience a seamless financial operation.{' '}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="login-main ">
                    <div className="login-center">
                        <div className="py-15 pt-3">
                            <div
                                className="  m-auto mb-3 responsive-card img-center mt-8 cursor-pointer"
                                onClick={done}
                            >
                                <img src={NewOAGFLogoLogin} alt="Logo" className="nav-logo" />
                            </div>

                            <div className="card m-auto p-11 responsive-card">
                                <form
                                    onSubmit={formik.handleSubmit}
                                    noValidate
                                    className="form align-middle  gx-0 gy-5 mt-5"
                                >
                                    <span className="login_text login-text">Login</span>
                                    <div className="mt-5">
                                        <div className="row mt-1">
                                            <div className="col-lg-4 col-form-label py-0 input-height">
                                                {formik.values.username.length !== 0 && (
                                                    <label className="required fw-bold fs-9">Email</label>
                                                )}
                                            </div>

                                            <div
                                                className={`form-control form-control-solid !formik.errors.username ? "border-success" : ""`}
                                            >
                                                <img src={envelope} alt="username" className="icon-style" />

                                                <input
                                                    type="text"
                                                    placeholder="Email*"
                                                    className={'no-outline ps-4 text-dark fs-8'}
                                                    {...formik.getFieldProps('username')}
                                                />
                                            </div>

                                            <div className="fv-plugins-message-container input-height">
                                                <div className="fv-help-block fs-9 text-danger">
                                                    {formik.touched.username &&
                                                        formik.errors.username &&
                                                        formik.errors.username}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-1">
                                            <div className="col-lg-4 col-form-label py-0 input-height">
                                                {formik.values.password.length !== 0 && (
                                                    <label className="required fw-bold fs-9">Password</label>
                                                )}
                                            </div>

                                            <div
                                                className={`form-control form-control-solid !formik.errors.username ? "border-success" : ""`}
                                            >
                                                <img src={lockSimple} alt="password" className="icon-style" />

                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder="Password * "
                                                    className={'no-outline ps-4 text-dark fs-8 w-90'}
                                                    {...formik.getFieldProps('password')}
                                                />

                                                <i
                                                    className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                                                    onClick={togglePasswordVisibility}
                                                    style={{
                                                        marginLeft: '-10px',
                                                        cursor: 'pointer',
                                                        color: 'GrayText',
                                                    }}
                                                ></i>
                                            </div>

                                            <div className="fv-plugins-message-container input-height">
                                                <div className="fv-help-block fs-9 text-danger">
                                                    {formik.touched.password &&
                                                        formik.errors.password &&
                                                        formik.errors.password}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-1">
                                            <div className="login-rem-grid">
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        placeholder="rememberMe*"
                                                        className=""
                                                        {...formik.getFieldProps('rememberMe')}
                                                    />

                                                    <span className="login-remeber-text" style={{ marginLeft: '5px' }}>
                                                        Remember Me
                                                    </span>
                                                </div>

                                                <Link to="/auth/forgot-password">
                                                    {' '}
                                                    <span className="login-forgot-password-text">Forgot Password?</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="login-text mt-5">
                                        <div className="">
                                            <button
                                                id="kt_password_cancel "
                                                type="submit"
                                                className={`btn ${
                                                    !(formik.isValid && !isLoading && formik.dirty)
                                                        ? 'btn-secondary'
                                                        : 'btn-success'
                                                } text-white px-7 py-2`}
                                                disabled={!(formik.isValid && !isLoading && formik.dirty)}
                                            >
                                                {!isLoading && <span className="indicator-label">Login</span>}
                                                {isLoading && (
                                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </div>

                                    <span className="login-text mt-5  login-remeber-text">
                                        Don’t have an account?{' '}
                                        <Link to="/auth/register">
                                            <span style={{ color: '#1F8505' }}>Register</span>
                                        </Link>
                                    </span>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    );
};

export { Login };


