import Header from 'app/components/dashboard/Header';
import SideBar from 'app/components/dashboard/SideBar';
import { isPathExists } from 'app/routing/PrivateRoutes';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Unauthorized } from '../../../routing/Unauthorized';

type Props = {
    children?: any;
};

const Layout: React.FC<Props> = ({ children }) => {
    const [dashboardSidebar, setDashboardSidebar] = useState(false);

    function showMenu() {
        setDashboardSidebar(!dashboardSidebar);
    }

    return (
        <div className={'mainDashboard'}>
            <header>
                <Header showMenu={showMenu} />
            </header>
            <section className={'dashboardRow2'}>
                <div className={'dashboardSidebar'}>
                    <SideBar dashboardSidebar={dashboardSidebar} showMenu={showMenu} />
                </div>
                {isPathExists() || window.location.pathname === '/admin' ? <Outlet /> : <Unauthorized />}
            </section>
        </div>
    );
};

export { Layout };
